import * as React from "react";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import HeadData from "../data/HeadData";

// markup
const NotFoundPage = () => {
  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData title="Not Found" />
      <Header />
      <main className="flex flex-col items-center justify-center z-10 my-20 mx-10">
        <h1>Oops! Sorry, the page you were looking for was not found.</h1>
      </main>
      <Footer/>
    </div>
  );
};

export default NotFoundPage;
